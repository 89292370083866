<template>
  <div :class="className">
    <van-nav-bar
      title="隐患排查"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in troubleConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== troubleConf.length - 1"
      >
        <template #title>
          <van-icon
            :name="item.icon"
            :color="item.iconColor"
            class-prefix="iconfont"
            class="van-cell__left-icon"
          />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  name: "TroubleDispatch",
  data() {
    return {
      className: "trouble-dispatch",
      troubleConf: [
        {
          icon: "tongjifenxi-baojingtongji",
          iconColor: "#FC4935",
          title: "我的隐患治理",
          routerName: "trouble-list",
          routerQuery: { type: "me" }
        },
        {
          icon: "tongjifenxi-zuoyetongji",
          iconColor: "#1D9EFF",
          title: "隐患治理查询",
          routerName: "trouble-list",
          routerQuery: { type: "all" }
        },
        {
          icon: "tongjifenxi-yinhuantongji",
          iconColor: "#FFB41F",
          title: "隐患排查计划",
          routerName: "PlanList"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.trouble-dispatch {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
</style>
